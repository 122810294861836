<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Add Postcode">
          <validation-observer ref="addPostcodeValidator">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Postcode"
                    label-for="postcode"
                  >
                    <validation-provider
                      v-slot="validationContext"
                      name="postcode"
                      :rules="{ required: true }"
                    >
                      <b-form-input
                        id="postcode"
                        v-model="form.code"
                        name="postcode"
                        :state="getValidationState(validationContext)"
                        placeholder="Enter Postcode"
                      />
                      <b-form-invalid-feedback>{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <div class="d-flex justify-content-end">
                    <router-link to="/postcode">
                      <b-button
                        variant="primary"
                        type="reset"
                        class="mr-1"
                      >
                        Cancel
                      </b-button>
                    </router-link>
                    <b-button
                      variant="primary"
                      type="submit"
                      @click.prevent="validateForm"
                    >
                      Add
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const postcodeModule = createNamespacedHelpers('postcode')

export default {
  data() {
    return {
      form: {
        code: '',
      },
    }
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...postcodeModule.mapActions(['ADD_POSTCODE']),

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async validateForm() {
      this.$refs.addPostcodeValidator.validate().then(async success => {
        if (success) {
          try {
            this.UPDATE_LOADER(true)
            const resp = await this.ADD_POSTCODE(this.form)
            if (resp) {
              this.form.code = ''
              this.$nextTick(() => {
                this.$refs.addPostcodeValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Postcode Created!',
                  icon: 'PlusIcon',
                  variant: 'success',
                  text: 'Postcode has been created successfully!',
                },
              })
            }
            this.UPDATE_LOADER(false)
          } catch (error) {
            this.UPDATE_LOADER(false)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
